import { userValidation } from "../utils/helper";

const eCommerce = [
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/ecommerce/coupons",
    name: "ECoupon",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/emailCampaign/eCommerce/eCommerceCoupon/eCommerceCoupon.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Coupon",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/ecommerce/coupon/create",
    name: "ECreateCoupon",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/emailCampaign/eCommerce/eCommerceCoupon/tabsComponent/uploadedCoupons/createCoupons/createCoupons.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Coupon",
          to: {
            name: "ECoupon",
            props: true,
          },
        },
        {
          text: "Create",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/ecommerce/coupon/add-coupon",
    name: "EAddCoupon",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/emailCampaign/eCommerce/eCommerceCoupon/tabsComponent/uploadedCoupons/addCouponCodes/addCouponCodes.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Coupon",
          to: {
            name: "ECoupon",
            props: true,
          },
        },
        {
          text: "Add Coupon",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/ecommerce/products",
    name: "EProducts",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/emailCampaign/eCommerce/eCommerceProduct/eCommerceProductList.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Products",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/ecommerce/products/productdetails/:productId",
    name: "EProductDetails",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/emailCampaign/eCommerce/eCommerceProduct/eCommerceProductDetails/eCommerceProductDetails.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "products",
          to: {
            name: "EProducts",
            props: true,
          },
        },
        {
          text: "product details",
        },
      ],
      requiresAuth: true,
    },
  },
];

export default eCommerce;
