import { getDashboardDetails } from "@/services/clubDetails/dashboard.service";

export type DashboardState = {
  dashboardData: any;
  loading: boolean;
  error: any;
};

const state: DashboardState = {
  dashboardData: null,
  loading: false,
  error: null,
};

const dashboard = {
  state,
  getters: {
    dashboardData(state: DashboardState): any {
      return state.dashboardData;
    },
    isDashboardLoading(state: DashboardState): boolean {
      return state.loading;
    },
    dashboardError(state: DashboardState): any {
      return state.error;
    },
  },
  mutations: {
    SET_DASHBOARD_DATA(state: DashboardState, payload: any) {
      state.dashboardData = payload;
    },
    SET_DASHBOARD_LOADING(state: DashboardState, loading: boolean) {
      state.loading = loading;
    },
    SET_DASHBOARD_ERROR(state: DashboardState, error: any) {
      state.error = error;
    },
  },
  actions: {
    fetchDashboardDetails({ commit }: any, payload: any) {
      commit("SET_DASHBOARD_LOADING", true);
      commit("SET_DASHBOARD_ERROR", null);

      getDashboardDetails(payload)
        .then((response) => {
          commit("SET_DASHBOARD_DATA", response.data.data);
          commit("SET_DASHBOARD_LOADING", false);
        })
        .catch((error: any) => {
          commit("SET_DASHBOARD_ERROR", error);
          commit("SET_DASHBOARD_LOADING", false);
        });
    },
  },
};

export default dashboard;
