import { userValidation } from "../utils/helper";

const settingsRoute = [
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/settings",
    name: "Settings",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        { text: "Settings" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/clubDetails/settings/SettingComponent.vue"),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/settings/package/:type",
    name: "SettingsPackage",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Settings",
          to: {
            name: "Settings",
            props: true,
          },
        },
        { text: "Package" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/clubDetails/settings/package/editPackagePlan/editPackagePlan.vue"),
  },
];

export default settingsRoute;
