const formRoutes = [
  {
    path: "/club-details/:id/forms-list",
    name: "formsListView",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        { text: "Forms" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/formBuilder/formsListView/formsListView.vue"),
  },
  {
    path: "/club-details/:id/create-form-config",
    name: "createFormConfig",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Forms",
          to: {
            name: "formsListView",
            props: true,
          },
        },
        {
          text: "Create Form",
          to: {
            name: "createFormConfig",
            props: true,
          },
        },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/formBuilder/createFormConfiguration/createFormConfiguration.vue"
      ),
  },
  {
    path: "/club-details/:id/create-form-builder",
    name: "createFormBuilder",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Forms",
          to: {
            name: "formsListView",
            props: true,
          },
        },
        {
          text: "Create Form",
          to: {
            name: "createFormConfig",
            props: true,
          },
        },
        {
          text: "Form Builder",
          to: {
            name: "formBuilder",
            props: true,
          },
        },
      ],
      requiresAuth: true,
    },
    component: () => import("../components/formBuilder/formBuilder.vue"),
  },
  //update form
  {
    path: "/club-details/:id/forms/:formId/update-form",
    name: "updateFormBuilder",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Forms",
          to: {
            name: "formsListView",
            props: true,
          },
        },
        {
          text: "Update Form",
          to: {
            name: "updateFormBuilder",
            props: true,
          },
        },
      ],
      requiresAuth: true,
    },
    component: () => import("../components/formBuilder/formBuilder.vue"),
  },
  {
    path: "/club-details/:id/view-form-details/:formId",
    name: "viewFormDetails",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Forms",
          to: {
            name: "formsListView",
            props: true,
          },
        },
        {
          text: "Form Details",
          to: {
            name: "viewFormDetails",
            props: true,
          },
        },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/formBuilder/viewFormDetails/viewFormDetails.vue"),
  },
  {
    path: "/club-details/:id/view-form-details/:formId/analytics",
    name: "FormAnalytics",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Forms",
          to: {
            name: "formsListView",
            props: true,
          },
        },
        {
          text: "Form Details",
          to: {
            name: "viewFormDetails",
            props: true,
          },
        },
        {
          text: "Analytics",
        },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/formBuilder/formsListView/formAnalytics/formAnalytics.vue"),
  },
];

export default formRoutes;
