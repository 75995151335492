import { DELETE, GET, POST, PUT } from "@/service";

export const getMenuWithPermissionService = (payload: any) => {
  return GET(`api/v1/club/get-menu-with-permission`, {
    params: payload,
  });
};

export const getCampaignPreviousTestEmailsService = (ClubId: any) => {
  return GET(`api/v1/campaign/${ClubId}/get-previous-test-email`);
};

export const mapWithPluginService = (payload: any) => {
  return POST(`api/v1/club/${payload?.clubId}/map-with-plugin`, payload);
};
